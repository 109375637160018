import React from "react";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
  Title,
} from "chart.js";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
  Title
);

const MetricGraph = ({ series, metric_chart_data, prefix, suffix }) => {
  //   console.log(series, "seriesseries");

  const segments = {
    colors: [
      "rgb(255, 77, 77)",
      "rgb(77, 148, 255)",
      "rgb(252, 255, 77)",
      "rgb(77, 255, 77)",
    ],
  };

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * segments.colors.length);
    return segments.colors[randomIndex];
  };

  const data = {
    labels: metric_chart_data?.map((d) => d.weekDate),
    datasets: [
      {
        label: "Metric Value",
        data: metric_chart_data?.map((d) => d.metric_value),
        segment: {
          borderColor: (ctx) => {
            const index = ctx.p1DataIndex;
            const pointSegment = metric_chart_data?.[index]?.color;

            return pointSegment;
          },
        },
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 4,
        pointBackgroundColor: (ctx) => {
          if (!ctx.raw) return "white";
          const index = ctx.dataIndex;
          const pointSegment = metric_chart_data?.[index]?.color;

          return pointSegment;
        },
      },
      {
        label: "EMA Value",
        data: metric_chart_data?.map((d) => d.ema_value),
        borderColor: "rgba(0, 0, 0, 0.5)",
        borderDash: [5, 5],
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 2,
        pointBackgroundColor: "rgba(0, 0, 0, 0.5)",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          font: {
            size: 12, // Change legend font size
          },
          color: "#007bff", // Change legend text color (Blue)
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.parsed.y || 0;
            return `${label}: ${value.toLocaleString()}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Week Date",
          font: {
            size: 12,
          },
        },
        ticks: {
          font: {
            size: 11,
            weight: 500,
          },
          color: "#071437",
        },
        grid: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
          drawOnChartArea: true,
          drawTicks: false,
          drawBorder: false,
          borderDash: [5, 5],
          borderDashOffset: 2,
        },
      },
      y: {
        title: {
          display: false,
          text: "Value",
        },
        ticks: {
          font: {
            size: 10,
            weight: 400,
          },
          color: "#071437",
          callback: function (value) {
            return (
              prefix +
              Number(value).toLocaleString("en-US", {
                maximumFractionDigits: 2,
              }) +
              suffix
            );
          },
        },
        grid: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
          drawOnChartArea: false, // Prevents grid lines across the entire chart
          drawTicks: false,
          borderDash: [5, 5],
          drawBorder: true, // Ensures the border line at the start
        },
      },
    },
  };
  return (
    <div style={{ width: "100%", height: "265px" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default MetricGraph;
