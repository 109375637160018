import {
  DatePicker,
  Empty,
  Input,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import InfoVideo from "../../../../components/header-icons";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import ExportFile from "./export-file";
import { ErrorModal } from "../../../../components/upload-modals";
import { RenderTable } from "../../../../config";
dayjs.extend(quarterOfYear);
const Financial = ({ locationData }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);

  const [filterOptions, setFilterOptions] = useState({});
  const [filterOptionsLoading, setFilterOptionsLoading] = useState(true);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [sortFilters, setSortFilters] = useState({
    field_name: "cnt_posted_date",
    sort_by: "desc",
  });
  const [filters, setFilters] = useState({
    search_key: null,
    // type: null,
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
    event_type: null,
    event_name: null,
    line_item_name: null,
  });

  const CommaAndFloat = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const Comma = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    { label: "This Week", value: [dayjs().startOf("week"), dayjs()] },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];
  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });
  const columns = [
    { title: "Sales Channel", dataIndex: "sales_channel" },
    {
      title: "Date",
      dataIndex: "cnt_posted_date",
      render: (_, e) => {
        return <span>{e?.cnt_posted_date}</span>;
      },
    },
    { title: "Amazon Order ID", dataIndex: "amazon_order_id" },
    { title: "Seller Order ID", dataIndex: "seller_order_id" },
    { title: "Order Item ID", dataIndex: "order_item_id" },
    { title: "Seller SKU", dataIndex: "seller_sku" },
    { title: "Event Type", dataIndex: "event_type" },
    { title: "Event Name", dataIndex: "event_name" },
    { title: "Line Item Name", dataIndex: "line_item_name" },

    {
      title: "Currency",
      dataIndex: "currency_code",
      render: (text) => text.toUpperCase(),
    },
    { title: "Quantity", dataIndex: "quantity" },

    { title: "Total", dataIndex: "total", render: (text) => `$${text}` },
    { title: "Item COGS", dataIndex: "item_cogs" },

    {
      title: "Financial Event Group ID",
      dataIndex: "financial_event_group_id",
    },
    { title: "Trace ID", dataIndex: "trace_id" },
    { title: "Ad Invoice ID", dataIndex: "ad_invoice_id" },
    { title: "Ad Charge Method", dataIndex: "ad_charge_method" },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => (
        <Tooltip title={text}>
          <div className="truncate-description">{text || "-"}</div>
        </Tooltip>
      ),
    },

    // {
    //   title: "Date",
    //   dataIndex: "date_time",
    //   key: "date_time",
    //   width: 180,
    // },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   width: 160,
    //   render: (e) => {
    //     return <Tag color="purple">{e || "-"}</Tag>;
    //   },
    // },

    // {
    //   title: "Order ID",
    //   dataIndex: "order_id",
    //   key: "order_id",
    //   width: 190,
    //   render: (e) => {
    //     return <Tag color="blue">{e || "-"}</Tag>;
    //   },
    // },
    // {
    //   title: "SKU",
    //   dataIndex: "sku",
    //   key: "sku",
    //   width: 240,
    //   render: (e) => {
    //     return <Tag color="cyan">{e || "-"}</Tag>;
    //   },
    // },
    // {
    //   title: "Quantity",
    //   dataIndex: "quantity",
    //   key: "quantity",
    //   width: 115,
    //   align: "right",
    //   render: (e) => {
    //     return Comma(e);
    //   },
    // },
    // {
    //   title: "Marketplace",
    //   dataIndex: "marketplace",
    //   key: "marketplace",
    //   width: 135,
    // },
    // {
    //   title: "Tax Collection Model",
    //   dataIndex: "tax_collection_model",
    //   key: "tax_collection_model",
    //   width: 200,
    // },
    // {
    //   title: "Product Sales ($)",
    //   dataIndex: "product_sales",
    //   key: "product_sales",
    //   width: 160,
    //   align: "right",
    //   render: (e) => {
    //     return CommaAndFloat(e);
    //   },
    // },
    // {
    //   title: "Product Sales Tax ($)",
    //   dataIndex: "product_sales_tax",
    //   key: "product_sales_tax",
    //   width: 180,
    //   align: "right",
    //   render: (e) => {
    //     return CommaAndFloat(e);
    //   },
    // },
    // {
    //   title: "Shipping Credits",
    //   dataIndex: "shipping_credits",
    //   key: "shipping_credits",
    //   width: 180,
    //   align: "right",
    //   render: (e) => {
    //     return CommaAndFloat(e);
    //   },
    // },
    // {
    //   title: "Shipping Credits Tax",
    //   dataIndex: "shipping_credits_tax",
    //   key: "shipping_credits_tax",
    //   width: 200,
    //   align: "right",
    //   render: (e) => {
    //     return CommaAndFloat(e);
    //   },
    // },
    // {
    //   title: "Gift Wrap Credits",
    //   dataIndex: "gift_wrap_credits",
    //   key: "gift_wrap_credits",
    //   width: 180,
    //   align: "right",
    //   render: (e) => {
    //     return CommaAndFloat(e);
    //   },
    // },
    // {
    //   title: "Gift Wrap Credits Tax",
    //   dataIndex: "giftwrap_credits_tax",
    //   key: "giftwrap_credits_tax",
    //   width: 210,
    //   align: "right",
    //   render: (e) => {
    //     return CommaAndFloat(e);
    //   },
    // },
    // {
    //   title: "Promotional Rebates",
    //   dataIndex: "promotional_rebates",
    //   key: "promotional_rebates",
    //   width: 210,
    //   align: "right",
    //   render: (e) => {
    //     return CommaAndFloat(e);
    //   },
    // },
    // {
    //   title: "Marketplace Withheld Tax",
    //   dataIndex: "marketplace_withheld_tax",
    //   key: "marketplace_withheld_tax",
    //   width: 246,
    //   align: "right",
    //   render: (e) => {
    //     return CommaAndFloat(e);
    //   },
    // },
    // {
    //   title: "Selling Fees",
    //   dataIndex: "selling_fees",
    //   key: "selling_fees",
    //   width: 150,
    //   align: "right",
    //   render: (e) => {
    //     return CommaAndFloat(e);
    //   },
    // },
    // {
    //   title: "FBA Fees",
    //   dataIndex: "fba_fees",
    //   key: "fba_fees",
    //   width: 120,
    //   align: "right",
    //   render: (e) => {
    //     return CommaAndFloat(e);
    //   },
    // },
    // {
    //   title: "Other",
    //   dataIndex: "other",
    //   key: "other",
    //   align: "right",
    //   render: (e) => {
    //     return CommaAndFloat(e);
    //   },
    //   width: 110,
    // },
    // {
    //   title: "Total",
    //   dataIndex: "total",
    //   key: "total",
    //   width: 110,
    //   align: "right",
    //   render: (e) => {
    //     return CommaAndFloat(e);
    //   },
    // },
  ];
  const getList = async (data) => {
    setTableLoading(true);
    const response = await MakeApiCall(
      `report/finance-data?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${
        data?.sort_by || ""
      }&is_export=0&search_key=${data?.search_key || ""}&type=${
        data?.type || ""
      }&start_date=${data?.start_date || ""}&end_date=${
        data?.end_date || ""
      }&event_type=${data?.event_type || ""}&event_name=${
        data?.event_name || ""
      }&line_item_name=${data?.line_item_name || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setTableLoading(false);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setList(response?.data?.records || []);
      setLoading(false);
    } else {
      setTableLoading(false);
      setList([]);
      setLoading(false);
      ErrorModal(response?.message);
      setTotalPage(0);
    }
  };
  const getEventTypesAction = async (data) => {
    setFilterOptionsLoading(true);
    setFilterOptions({});
    const response = await MakeApiCall(
      `finance/get-event-types`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setFilterOptions(response?.data || {});
      setFilterOptionsLoading(false);
    } else {
      setFilterOptions({});
      setFilterOptionsLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList({ ...filters, ...sortFilters });
    getEventTypesAction();
    return () => {};
  }, []);

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.field : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj, ...filters });
  };
  return (
    <div className="card custom-ui">
      <div className="card-header">
        <div className="card-title">
          Financial
          <InfoVideo
            data={locationData}
            title={"financial"}
            className={"ms-3"}
          />
          <Input
            placeholder="Search by Order id, sku"
            className="w-175px ms-3"
            onPressEnter={() => {
              getList({
                ...filters,
                ...sortFilters,
              });
            }}
            value={filters?.search_key}
            allowClear
            onChange={(e) => {
              setFilters((prev) => {
                if (!e.target.value && prev) {
                  getList({
                    ...{
                      ...prev,
                      search_key: e.target.value,
                    },
                    ...sortFilters,
                  });
                }
                return {
                  ...prev,
                  search_key: e.target.value,
                };
              });
            }}
          />
          <button
            onClick={() => {
              getList({
                ...filters,
                ...sortFilters,
              });
            }}
            className="btn btn-sm ms-3 btn-search"
            style={{ padding: "5px 15px" }}
          >
            <svg
              width={15}
              height={15}
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m21 21-2.243-2.247-2.243-2.247" />
              <path d="M19 10.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Z" />
            </svg>
          </button>
          {/* <Select
            value={filters?.type}
            allowClear={true}
            onChange={(e) => {
              setFilters({
                ...filters,
                type: e,
              });
              getList({
                ...filters,
                type: e,
              });
            }}
            options={[
              {
                label: "ProductAdsPayment",
                value: "ProductAdsPayment",
              },
              {
                label: "Order",
                value: "Order",
              },
              {
                label: "Refund",
                value: "Refund",
              },
              {
                label: "Adjustment",
                value: "Adjustment",
              },
              {
                label: "Service Fee",
                value: "Service Fee",
              },
              {
                label: "CouponPayment",
                value: "CouponPayment",
              },
              {
                label: "Deal Fee",
                value: "Deal Fee",
              },
              {
                label: "DebtRecovery",
                value: "DebtRecovery",
              },
              {
                label: "Shipping Service",
                value: "Shipping Service",
              },
              {
                label: "Transfer",
                value: "Transfer",
              },
            ]}
            placeholder="Type"
            className="w-150px ms-3"
          /> */}
          <Select
            value={filters?.event_type}
            allowClear={true}
            onChange={(e) => {
              setFilters({
                ...filters,
                event_type: e,
              });
              getList({
                ...filters,
                event_type: e,
              });
            }}
            options={filterOptions?.event_types?.sort()?.map((r) => ({
              label: r,
              value: r,
            }))}
            placeholder="Event Type"
            className="w-150px ms-3"
            loading={filterOptionsLoading}
          />
          <Select
            value={filters?.event_name}
            allowClear={true}
            onChange={(e) => {
              setFilters({
                ...filters,
                event_name: e,
              });
              getList({
                ...filters,
                event_name: e,
              });
            }}
            options={filterOptions?.event_names?.sort()?.map((r) => ({
              label: r,
              value: r,
            }))}
            placeholder="Event Name"
            className="w-150px ms-3"
            loading={filterOptionsLoading}
          />
          <Select
            value={filters?.line_item_name}
            allowClear={true}
            onChange={(e) => {
              setFilters({
                ...filters,
                line_item_name: e,
              });
              getList({
                ...filters,
                line_item_name: e,
              });
            }}
            options={filterOptions?.line_item_names?.sort()?.map((r) => ({
              label: r,
              value: r,
            }))}
            placeholder="Line Item Names"
            className="w-150px ms-3"
            loading={filterOptionsLoading}
          />
        </div>
        <div className="card-toolbar">
          <DatePicker.RangePicker
            allowClear
            presets={presetRanges}
            value={
              filters?.start_date && filters?.end_date
                ? [
                    dayjs(filters?.start_date, "YYYY-MM-DD"),
                    dayjs(filters?.end_date, "YYYY-MM-DD"),
                  ]
                : []
            }
            onChange={(e) => {
              const obj = {
                ...filters,
                start_date: !e ? null : dayjs(e?.[0])?.format("YYYY-MM-DD"),
                end_date: !e ? null : dayjs(e?.[1])?.format("YYYY-MM-DD"),
              };
              getList({ ...obj, ...sortFilters });
              setFilters(obj);
            }}
            className="w-250px me-3"
            disabledDate={(current) => {
              return current && current > moment().endOf("day");
            }}
          />
          <ExportFile
            paramsBody={{
              source_type: "Data Source",
              record_type: "Financial",
              start_date: filters?.start_date,
              end_date: filters?.end_date,
              param_filter: {
                start_date: filters?.start_date,
                end_date: filters?.end_date,
                search_key: filters?.search_key,
                type: filters?.type,
                event_type: filters?.event_type,
                event_name: filters?.event_name,
                line_item_name: filters?.line_item_name,
              },
            }}
            title="Financial"
            hideTooltip={filters?.start_date && filters?.end_date}
            api={`report/finance-data?start_date=${
              filters?.start_date || ""
            }&end_date=${filters?.end_date || ""}&is_export=1&field_name=${
              sortFilters?.field_name || ""
            }&sort_by=${sortFilters?.sort_by || ""}&search_key=${
              filters?.search_key || ""
            }&event_type=${filters?.event_type || ""}&event_name=${
              filters?.event_name || ""
            }&line_item_name=${filters?.line_item_name || ""}`}
          />
        </div>
      </div>
      <div className="card-body  pt-0">
        {loading ? (
          <TableLoading
            id="test-table"
            columns={[1, 2, 3, 4, 5]}
            checkBoxVal={true}
            actions={[1, 2]}
          />
        ) : list?.length === 0 ? (
          <Empty style={{ minHeight: "500px" }} />
        ) : (
          <div className="">
            <Table
              dataSource={list}
              fixed={true}
              sticky={{
                offsetHeader: "0px",
              }}
              loading={tableLoading}
              scroll={{ x: "max-content", y: "calc(100vh - 405px)" }}
              columns={columns?.map((d) => ({
                ...d,
                className: "minWidth-table",
                ...PropsFilter(d?.dataIndex),
                render: (props, row, index) =>
                  RenderTable(props, row, index, d),
              }))}
              pagination={false}
              onChange={handleChange}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even-row" : "odd-row";
              }}
              bordered
              size="small"
            />
          </div>
        )}
        <Pagination
          loading={loading}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={(e) => {
            setLoading(true);
            setPageSize(e);
            setPage(1);
            getList({
              page: 1,
              pageSize: e,
              ...sortFilters,
              ...filters,
            });
          }}
          onPageNo={(e) => {
            setLoading(true);
            setPage(e);
            getList({
              page: e,
              ...filters,
              ...sortFilters,
            });
          }}
        />
      </div>
    </div>
  );
};

export default Financial;
