import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Empty,
  Input,
  Select,
  Table,
  Tag,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import dayjs from "dayjs";
import moment from "moment";
import ExportFile from "./export-file";
import { NumberWithCommas, RenderTable } from "../../../../config";
import InfoVideo from "../../../../components/header-icons";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { ErrorModal } from "../../../../components/upload-modals";
dayjs.extend(quarterOfYear);
const SpCampaigns = ({ locationData }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [tableLoading, setTableLoading] = useState(true);
  const [page, setPage] = useState(1);

  const [filters, setFilters] = useState({
    campaign_name: null,
    campaign_status: null,
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
  });

  const [sortFilters, setSortFilters] = useState({
    field_name: "report_date",
    sort_by: "desc",
  });

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    { label: "This Week", value: [dayjs().startOf("week"), dayjs()] },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];
  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });
  const columns = [
    {
      title: "ID",
      align: "center",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Report Date",

      dataIndex: "report_date", // Replace 'report_date' with the actual data index from your dataset
      key: "report_date",
      align: "left",
      render: (e, _, i) => {
        return <div className="d-flex">{e}</div>;
      },
    },
    {
      title: "Campaign Name",
      width: 400,
      dataIndex: "campaign_name", // Replace 'campaign_name' with the actual data index from your dataset
      key: "campaign_name",
    },
    {
      title: "Campaign ID",

      dataIndex: "campaign_id", // Replace 'campaign_id' with the actual data index from your dataset
      key: "campaign_id",

      render: (e) => {
        return <Tag color="blue">{e || "-"}</Tag>;
      },
    },
    {
      title: "Campaign Status",
      dataIndex: "campaign_status", // Replace 'campaign_status' with the actual data index from your dataset
      key: "campaign_status",

      render: (e) => {
        return <Tag color={e === "ENABLED" ? "green" : "red"}>{e || "-"}</Tag>;
      },
    },
    {
      title: "Campaign Budget Amount ($)",
      dataIndex: "campaign_budget_amount", // Replace 'campaign_budget_amount' with the actual data index from your dataset
      key: "campaign_budget_amount",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      title: "Campaign Budget Type",
      dataIndex: "campaign_budget_type", // Replace 'campaign_budget_type' with the actual data index from your dataset
      key: "campaign_budget_type",
    },
    {
      title: "Campaign Rule Based Budget Amount ($)",
      dataIndex: "campaign_rule_based_budget_amount", // Replace 'campaign_rule_based_budget_amount' with the actual data index from your dataset
      key: "campaign_rule_based_budget_amount",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      title: "Campaign Applicable Budget Rule ID",
      dataIndex: "campaign_applicable_budget_rule_id",
      key: "campaign_applicable_budget_rule_id",
    },
    {
      title: "Campaign Applicable Budget Rule Name",
      dataIndex: "campaign_applicable_budget_rule_name",
      key: "campaign_applicable_budget_rule_name",
    },
    {
      title: "Campaign Budget Currency Code",
      dataIndex: "campaign_budget_currency_code",
      key: "campaign_budget_currency_code",
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      align: "right",
      render: (e) => {
        return NumberWithCommas(e);
      },
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      align: "right",
      render: (e) => {
        return NumberWithCommas(e);
      },
    },
    {
      title: "Costs ($)",
      dataIndex: "cost",
      key: "cost",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      title: "Purchases 1D",
      dataIndex: "purchases1d",
      key: "purchases1d",
      align: "right",
      render: (e) => {
        return NumberWithCommas(e);
      },
    },
    {
      title: "Purchases 7D",
      dataIndex: "purchases7d",
      key: "purchases7d",
      align: "right",
      render: (e) => {
        return NumberWithCommas(e);
      },
    },
    {
      title: "Purchases 14D",
      dataIndex: "purchases14d",
      key: "purchases14d",
      align: "right",
      render: (e) => {
        return NumberWithCommas(e);
      },
    },
    {
      title: "Purchases 30D",
      dataIndex: "purchases30d",
      key: "purchases30d",
      align: "right",
      render: (e) => {
        return NumberWithCommas(e);
      },
    },
    {
      title: "Purchases Same SKU 1D",
      dataIndex: "purchases_same_sku1d",
      key: "purchases_same_sku1d",
      align: "right",
      render: (e) => {
        return NumberWithCommas(e);
      },
    },
    {
      title: "Purchases Same SKU 7D",
      dataIndex: "purchases_same_sku7d",
      key: "purchases_same_sku7d",
      align: "right",
      render: (e) => {
        return NumberWithCommas(e);
      },
    },
    {
      title: "Purchases Same SKU 14D",
      dataIndex: "purchases_same_sku14d",
      key: "purchases_same_sku14d",
      align: "right",
      render: (e) => {
        return NumberWithCommas(e);
      },
    },
    {
      title: "Purchases Same SKU 30D",
      dataIndex: "purchases_same_sku30d",
      key: "purchases_same_sku30d",
      align: "right",
      render: (e) => {
        return NumberWithCommas(e);
      },
    },
    {
      title: "Units Sold Clicks 1D",
      dataIndex: "units_sold_clicks1d",
      key: "units_sold_clicks1d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      title: "Units Sold Clicks 7D",
      dataIndex: "units_sold_clicks7d",
      key: "units_sold_clicks7d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      title: "Units Sold Clicks 14D",
      dataIndex: "units_sold_clicks14d",
      key: "units_sold_clicks14d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      title: "Units Sold Clicks 30D",
      dataIndex: "units_sold_clicks30d",
      key: "units_sold_clicks30d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      title: "Sales 1 Day ($)",
      dataIndex: "sales1d",
      key: "sales1d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      title: "Sales 7 Days  ($)",
      dataIndex: "sales7d",
      key: "sales7d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      title: "Sales 14 Days  ($)",
      dataIndex: "sales14d",
      key: "sales14d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      title: "Sales 30 Days  ($)",
      dataIndex: "sales30d",
      key: "sales30d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      title: "Attributed Sales Same SKU 1 Day  ($)",
      dataIndex: "attributed_sales_same_sku1d",
      key: "attributed_sales_same_sku1d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      title: "Attributed Sales Same SKU 7 Days ($)",
      dataIndex: "attributed_sales_same_sku7d",
      key: "attributed_sales_same_sku7d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      title: "Attributed Sales Same SKU 14 Days ($)",
      dataIndex: "attributed_sales_same_sku14d",
      key: "attributed_sales_same_sku14d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      title: "Attributed Sales Same SKU 30 Days ($)",
      dataIndex: "attributed_sales_same_sku30d",
      key: "attributed_sales_same_sku30d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      title: "Units Sold Same SKU 1 Day",
      dataIndex: "units_sold_same_sku1d",
      key: "units_sold_same_sku1d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      title: "Units Sold Same SKU 7 Days",
      dataIndex: "units_sold_same_sku7d",
      key: "units_sold_same_sku7d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      title: "Units Sold Same SKU 14 Days",
      dataIndex: "units_sold_same_sku14d",
      key: "units_sold_same_sku14d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      title: "Units Sold Same SKU 30 Days",
      dataIndex: "units_sold_same_sku30d",
      key: "units_sold_same_sku30d",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },
    {
      title: "Kindle Edition Normalized Pages Read 14 Days",
      dataIndex: "kindle_edition_normalized_pages_read14d",
      key: "kindle_edition_normalized_pages_read14d",
      align: "right",
      render: (e) => {
        return NumberWithCommas(e);
      },
    },
    {
      title: "Kindle Edition Normalized Pages Royalties 14 Days",
      dataIndex: "kindle_edition_normalized_pages_royalties_14d",
      key: "kindle_edition_normalized_pages_royalties_14d",
      align: "right",
      render: (e) => {
        return NumberWithCommas(e);
      },
    },
    {
      title: "Campaign Bidding Strategy",
      dataIndex: "campaign_bidding_strategy",
      key: "campaign_bidding_strategy",
      align: "right",
      render: (e) => {
        return NumberWithCommas(e);
      },
    },
    {
      title: "Cost Per Click ($)",
      dataIndex: "cost_per_click",
      key: "cost_per_click",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      title: "Click Through Rate (%)",
      dataIndex: "click_through_rate",
      key: "click_through_rate",
      align: "right",
      render: (e) => {
        return (e || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    // {
    //   title: "Spend ($)",
    //   dataIndex: "spend",
    //   key: "spend",
    //   align: "right",
    //   render: (e) => {
    //     return (e || 0).toLocaleString("en-US", {
    //       minimumFractionDigits: 2,
    //       maximumFractionDigits: 2,
    //     });
    //   },
    // },
    {
      title: "Marketplace ID",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
    },
  ];

  const getList = async (data) => {
    setTableLoading(true);
    const response = await MakeApiCall(
      `advertising/sp-campaigns?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${
        data?.sort_by || ""
      }&campaign_name=${data?.campaign_name || ""}&campaign_status=${
        data?.campaign_status || ""
      }&start_date=${data?.start_date || ""}&end_date=${data?.end_date || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setTableLoading(false);
      setList(response?.data?.records || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setTableLoading(false);
      setLoading(false);
      setTotalPage(0);
      ErrorModal(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList({ ...filters, ...sortFilters });
    return () => {};
  }, []);

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.columnKey : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj, ...filters });
  };

  return (
    <div className="card custom-ui">
      <div className="card-header">
        <div className="card-title">
          Sponsored Products Campaign
          <InfoVideo
            data={locationData}
            title="sp_campaigns"
            className={"ms-3"}
          />
          <Input
            placeholder="Campaign Name"
            className="w-175px ms-3"
            allowClear
            onPressEnter={() => {
              getList({ ...filters, ...sortFilters });
            }}
            value={filters?.campaign_name}
            onChange={(e) => {
              setFilters((prev) => {
                if (!e.target.value && prev) {
                  getList({
                    ...{
                      ...prev,
                      campaign_name: e.target.value,
                    },
                    ...sortFilters,
                  });
                }
                return {
                  ...prev,
                  campaign_name: e.target.value,
                };
              });
            }}
          />
          <button
            onClick={() => {
              getList({ ...filters, ...sortFilters });
            }}
            className="btn btn-sm ms-3 btn-search"
            style={{ padding: "5px 15px" }}
          >
            <svg
              width={15}
              height={15}
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m21 21-2.243-2.247-2.243-2.247" />
              <path d="M19 10.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Z" />
            </svg>
          </button>
          <Select
            placeholder="Campaign Status"
            className="w-150px ms-3"
            value={filters?.campaign_status}
            onChange={(e) => {
              const obj = {
                ...filters,
                campaign_status: e,
              };
              setFilters(obj);
              getList({ ...obj, ...sortFilters });
            }}
            allowClear
            options={[
              {
                label: "ENABLED",
                value: "ENABLED",
              },
              {
                label: "PAUSED",
                value: "PAUSED",
              },
            ]}
          />
        </div>

        <div className="card-toolbar">
          <DatePicker.RangePicker
            allowClear
            presets={presetRanges}
            value={
              filters?.start_date && filters?.end_date
                ? [
                    dayjs(filters?.start_date, "YYYY-MM-DD"),
                    dayjs(filters?.end_date, "YYYY-MM-DD"),
                  ]
                : []
            }
            onChange={(e) => {
              const obj = {
                ...filters,
                start_date: e ? dayjs(e?.[0])?.format("YYYY-MM-DD") : null,
                end_date: e ? dayjs(e?.[1])?.format("YYYY-MM-DD") : null,
              };
              setFilters(obj);
              getList({ ...obj, ...sortFilters });
            }}
            className="w-250px me-3"
            disabledDate={(current) => {
              return current && current > moment().endOf("day");
            }}
          />

          <ExportFile
            paramsBody={{
              source_type: "Data Source",
              record_type: "SP Campaigns",
              start_date: filters?.start_date,
              end_date: filters?.end_date,
              param_filter: {
                start_date: filters?.start_date,
                end_date: filters?.end_date,
                campaign_name: filters?.campaign_name,
                campaign_status: filters?.campaign_status,
              },
            }}
            title="SP-Campaigns"
            hideTooltip={filters?.start_date && filters?.end_date}
            api={`advertising/sp-campaigns?start_date=${
              filters?.start_date || ""
            }&end_date=${filters?.end_date || ""}&is_export=1&field_name=${
              sortFilters?.field_name || ""
            }&sort_by=${sortFilters?.sort_by || ""}&campaign_name=${
              filters?.campaign_name || ""
            }&campaign_status=${filters?.campaign_status || ""}`}
          />
        </div>
      </div>
      <div className="card-body  pt-0" style={{ padding: "10px" }}>
        {tableLoading ? (
          <TableLoading
            id="test-table"
            columns={[1, 2, 3, 4, 5]}
            checkBoxVal={true}
            actions={[1, 2]}
          />
        ) : list?.length === 0 ? (
          <Empty style={{ minHeight: "500px" }} />
        ) : (
          <div className="">
            <div className="table-container">
              <Table
                dataSource={list}
                fixed={true}
                sticky={{
                  offsetHeader: "0px",
                }}
                loading={tableLoading}
                onChange={handleChange}
                scroll={{ x: "max-content", y: "calc(100vh - 405px)" }} // , y: "calc(100vh - 460px)"
                columns={columns?.map((d) => {
                  return {
                    ...d,
                    className: "minWidth-table",
                    ...PropsFilter(d?.dataIndex),
                    render: (props, row, index) =>
                      RenderTable(props, row, index, d, -1.3),
                  };
                })}
                pagination={false}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
              />
            </div>
          </div>
        )}
        <Pagination
          loading={loading}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={(e) => {
            setLoading(true);
            setPageSize(e);
            setPage(1);
            getList({
              page: 1,
              pageSize: e,
              ...sortFilters,
              ...filters,
            });
          }}
          onPageNo={(e) => {
            setLoading(true);
            setPage(e);
            getList({
              page: e,
              ...sortFilters,
              ...filters,
            });
          }}
        />
      </div>
    </div>
  );
};

export default SpCampaigns;
