import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";

import { Button, Select, Table, Tabs, message } from "antd";
import { MakeApiCall } from "../../../apis";
import { RenderTable } from "../../../config";
import Pagination from "../../../components/pagination";
import { useHistory, useLocation } from "react-router-dom";
import Link from "antd/es/typography/Link";
import * as XLSX from "xlsx";

const { TabPane } = Tabs;

const MappingFinanceEvents = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [unList, setUnList] = useState([]);
  const [unLoading, setUnLoading] = useState(true);

  const [ignoreList, setIgnoreList] = useState([]);
  const [ignoreLoading, setIgnoreLoading] = useState(true);

  const [lineItemList, setLineItemList] = useState([]);
  const [lineItemLoading, setlLineItemLoading] = useState(true);

  const [lineItemsList, setLineItemsList] = useState({});

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [totalPage_ignore, setTotalPage_ignore] = useState(0);
  const [pageSize_ignore, setPageSize_ignore] = useState(20);
  const [page_ignore, setPage_ignore] = useState(1);

  const [totalPage_, setTotalPage_] = useState(0);
  const [pageSize_, setPageSize_] = useState(20);
  const [page_, setPage_] = useState(1);
  const [editingRow, setEditingRow] = useState({});
  const [initialLineItems, setInitialLineItems] = useState({});

  const [sortFilters, setSortFilters] = useState({
    field_name: "seller_name",
    sort_by: "asc",
  });

  const GetIgnoreList = async (data) => {
    const response = await MakeApiCall(
      `finance-mapping/mapped-fees?field_name=line_item_name&sort_by=asc&is_ignore=1&isAll=1`,
      // `finance-mapping/mapped-fees?field_name=line_item_name&sort_by=asc&is_ignore=1&page=${
      //   data?.page || page_ignore || 1
      // }&per-page=${data?.pageSize || pageSize_ignore}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setTotalPage_ignore(response?.data?.pagination?.totalCount || 0);
      setIgnoreList(response?.data?.records || []);
      setIgnoreLoading(false);
    } else {
      message.warning(response?.message);
      setIgnoreLoading(false);
      setIgnoreList([]);
    }
  };
  const GetList = async (data) => {
    const response = await MakeApiCall(
      `finance-mapping/mapped-fees?is_mapped=1&is_ignore=0&isAll=1`,
      // `finance-mapping/mapped-fees?is_mapped=1&is_ignore=0&page=${
      //   data?.page || page_ || 1
      // }&per-page=${data?.pageSize || pageSize_}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      let lineItemsList_ = {};

      setLineItemsList((prev) => {
        lineItemsList_ = prev;
        return prev;
      });

      setTotalPage_(response?.data?.pagination?.totalCount || 0);

      setList(
        response?.data?.records?.map((d) => ({
          ...d,
          line_items: lineItemsList_?.[d?.finance_line_item_id],
        })) || []
      );
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
      setList([]);
    }
  };
  const GetUnList = async (data) => {
    const response = await MakeApiCall(
      // `finance-mapping/un-mapped-fees?is_mapped=0&is_ignore=0&page=${
      //   data?.page || page || 1
      // }&per-page=${data?.pageSize || pageSize}&field_name=${
      //   data?.field_name || ""
      // }&sort_by=${data?.sort_by || ""}`,
      `finance-mapping/un-mapped-fees?is_mapped=0&is_ignore=0&isAll=1`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      const records = response?.data?.records || [];
      setUnList(records);

      // On mount, store the initial values for each record by their unique key
      const initialValues = records.reduce((acc, item) => {
        if (
          Object.keys(acc || {})?.includes(item.fees_name) &&
          acc?.[item.fees_name]
        ) {
          return acc;
        }

        acc[item.fees_name] = item.finance_line_item_id;
        return acc;
      }, {});

      setInitialLineItems(initialValues);

      setUnLoading(false);
    } else {
      message.warning(response?.message);
      setUnLoading(false);
      setUnList([]);
    }
  };

  const UpdateMapping = async (data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `finance-mapping/update/${data?.id}`,
      "put",
      {
        finance_line_item_id: data?.finance_line_item_id,
        is_mapped: data?.is_mapped,
      },
      true
    );
    if (response?.status) {
      setLoading(true);
      GetList({
        page: page_,
        pageSize: pageSize_,
      });
      setUnLoading(true);

      GetUnList({
        page: page,
        pageSize: pageSize,
        ...sortFilters,
      });
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };
  const UpdateIsMapping = async (data, body) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `finance-mapping/${
        Object.hasOwn(body, "is_ignore") ? "ignore" : "update"
      }/${data?.id}`,
      "put",
      body,
      true
    );
    if (response?.status) {
      if (Object.hasOwn(body, "is_mapped")) {
        setUnLoading(true);
        GetUnList({
          page: page,
          pageSize: pageSize,
          ...sortFilters,
        });
        setLoading(true);

        GetList({
          page: page_,
          pageSize: pageSize_,
        });
      } else if (Object.hasOwn(body, "is_ignore")) {
        setUnLoading(true);
        GetUnList({
          page: page,
          pageSize: pageSize,
          ...sortFilters,
        });
        setIgnoreLoading(true);
        GetIgnoreList({
          page: 1,
          pageSize: pageSize_ignore,
        });
      }
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };
  const UpdateIsMappingMapped = async (data, body) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `finance-mapping/unmapped`,
      "post",
      {
        is_mapped: 0,
        event_type: data?.event_type,
        event_name: data?.event_name,
        line_item_name: data?.line_item_name,
      },
      true
    );
    if (response?.status) {
      setUnLoading(true);
      GetUnList();
      setLoading(true);
      GetList();
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };
  const FinanceMappingSyncFees = async (data, body) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `finance-mapping/sync-fees/${data?.id}`,
      "put",
      body,
      true
    );
    if (response?.status) {
      setUnLoading(true);
      GetUnList({
        page: page,
        pageSize: pageSize,
        ...sortFilters,
      });
      setLoading(true);
      GetList({
        page: page_,
        pageSize: pageSize_,
      });

      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const downloadExcel = (data, event_name) => {
    const wb = XLSX.utils.book_new();
    message.destroy();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, event_name);

    const excelBinaryString = XLSX.write(wb, {
      bookType: "xlsx",
      type: "binary",
    });

    const blob = new Blob([s2ab(excelBinaryString)], {
      type: "application/octet-stream",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${event_name}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    message.destroy();
  };

  const GetFinanceMappingDownload = async (id, event_name) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `finance-mapping/download-transaction/${id}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      if (response?.data?.length > 0) {
        downloadExcel(response?.data || [], event_name);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        message.warning("Data not found");
      }
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };
  const GetFinanceMapping = async () => {
    const response = await MakeApiCall(
      "finance-mapping/get-line-items",
      "get",
      null,
      true
    );
    if (response?.status) {
      setLineItemList(
        Object.entries(response?.data || {})?.map(([key, value]) => ({
          label: value,
          value: parseInt(key),
        }))
      );

      setLineItemsList(response?.data);
      setlLineItemLoading(false);
    } else {
      message.warning(response?.message);
      setlLineItemLoading(false);
      setUnList([]);
    }
  };

  const fetchData = async () => {
    setlLineItemLoading(true);
    await GetFinanceMapping();

    setLoading(true);
    await GetList({
      page: 1,
      pageSize: pageSize_,
    });

    setUnLoading(true);
    await GetUnList({
      page: 1,
      pageSize: pageSize,
      ...sortFilters,
    });

    setIgnoreLoading(true);
    await GetIgnoreList({
      page: 1,
      pageSize: pageSize_ignore,
    });
  };

  useEffect(() => {
    fetchData();

    return () => {};
  }, []);
  const PropsFilter = (type) => {
    return {
      sorter: (a, b) => {
        return typeof a?.[type] === "string"
          ? (a?.[type] || "")?.localeCompare(b?.[type] || "")
          : parseFloat(a?.[type] || 0) - parseFloat(b?.[type] || 0);
      },
    };
  };

  const getUniqueFilters = (data, type) => {
    const uniqueNames = [...new Set(data.map((item) => item?.[type]))]; // Get unique names
    return uniqueNames.map((event_type) => ({
      text: event_type,
      value: event_type,
    }));
  };

  const columns_ = [
    {
      title: "#",
      dataIndex: "index",
      filterIndex: "index",
      align: "center",
      render: (e) => {
        return <div className="d-flex justify-content-center w-100">{e}</div>;
      },
    },
    {
      title: "Event Type",
      dataIndex: "event_type",
      filterIndex: "event_type",
      filters: getUniqueFilters(unList, "event_type"), // Dynamically generated filters
      onFilter: (value, record) => record.event_type.indexOf(value) === 0,
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Event Name",
      dataIndex: "event_name",
      filterIndex: "event_name",
      filters: getUniqueFilters(unList, "event_name"), // Dynamically generated filters
      onFilter: (value, record) => record.event_name.indexOf(value) === 0,
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Line Item Name",
      dataIndex: "line_item_name",
      filterIndex: "line_item_name",
      filters: getUniqueFilters(unList, "line_item_name"), // Dynamically generated filters
      onFilter: (value, record) => record.line_item_name.indexOf(value) === 0,
      render: (e) => {
        return e || "-";
      },
    },

    {
      title: "Seller Name",
      dataIndex: "seller_name",
      filterIndex: "seller_name",
      defaultSortOrder: "ascend",
      filters: getUniqueFilters(unList, "seller_name"), // Dynamically generated filters
      onFilter: (value, record) => record.seller_name.indexOf(value) === 0,
    },
    {
      title: "Count",
      dataIndex: "number_of_transactions",
      filterIndex: "number_of_transactions",
      width: "50px",
      render: (e, row) => {
        return (
          <Link
            onClick={() => {
              GetFinanceMappingDownload(row?.id, row?.event_name);
            }}
          >
            {e || 0}
          </Link>
        );
      },
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      filterIndex: "marketplace",
      width: 100,
      filters: getUniqueFilters(unList, "marketplace"), // Dynamically generated filters
      onFilter: (value, record) => record.marketplace.indexOf(value) === 0,
    },
    {
      title: "Our Line Items",
      dataIndex: "ourLineItems",
      filterIndex: "ourLineItems",
      render: (_, record) => {
        const isEditable = editingRow === record?.fees_name;
        const initialValue = initialLineItems[record?.fees_name];

        const hasInitialValue = initialValue !== null && initialValue !== "";
        const isDisabled = !isEditable && hasInitialValue;

        return (
          <Select
            loading={lineItemLoading}
            allowClear
            options={lineItemList}
            placeholder="Select Our Line Items"
            value={
              record?.finance_line_item_id &&
              record?.finance_line_item_id !== "empty"
                ? parseInt(record?.finance_line_item_id)
                : null
            }
            onChange={(e) => {
              setEditingRow(record);
              setUnList((d) => {
                return d?.map((r) => {
                  if (
                    r?.line_item_name === record?.line_item_name &&
                    r?.event_type === record?.event_type &&
                    r?.event_name === record?.event_name
                  ) {
                    return {
                      ...r,
                      finance_line_item_id: e ? e : "empty",
                      is_edit: r?.finance_line_item_id
                        ? r?.is_edit || false
                        : true,
                    };
                  }
                  return { ...r };
                });
              });
            }}
            style={{ width: 200 }}
          />
        );
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        const disabled =
          record?.finance_line_item_id ||
          record?.finance_line_item_id === "empty"
            ? false
            : true;
        const disabled_ = record?.finance_line_item_id ? false : true;

        const isEditMode =
          editingRow?.event_name === record?.event_name &&
          editingRow?.event_type === record?.event_type &&
          editingRow?.line_item_name === record?.line_item_name;
        const initialValue =
          initialLineItems[record?.event_name] &&
          initialLineItems[record?.event_type] &&
          initialLineItems[record?.line_item_name]; // Get initial value from state
        const hasInitialValue = initialValue !== null && initialValue !== "";

        const dropdownDisabled = !isEditMode && hasInitialValue;

        return (
          <div className="d-flex">
            <button
              disabled={disabled_}
              onClick={() => {
                if (!dropdownDisabled) {
                  UpdateMapping(record);
                  setEditingRow(null);
                } else {
                  setEditingRow(record);
                }
              }}
              className="add-btn"
              style={{
                opacity: disabled ? 0.5 : 1,
                cursor: disabled ? "not-allowed" : "pointer",
                backgroundColor: !disabled_ ? "#2e3f52" : "#3b71b8",
              }}
            >
              {!disabled_ ? <div style={{ width: "2px" }}>&nbsp;</div> : <></>}
              {!disabled_ && !record?.is_edit ? "Edit" : "Save"}{" "}
              {!disabled_ ? <div style={{ width: "3px" }}>&nbsp;</div> : <></>}
            </button>

            <button
              disabled={!record?.finance_line_item_id}
              onClick={() => {
                FinanceMappingSyncFees(record, {
                  is_mapped: 1,
                  finance_line_item_id: record?.finance_line_item_id,
                });
              }}
              className="btn btn-sm btn-search ms-2"
              style={{
                height: "35px",
                paddingTop: "7px",
                opacity: record?.finance_line_item_id ? 1 : 0.5,
                cursor: record?.finance_line_item_id
                  ? "pointer"
                  : "not-allowed",
              }}
            >
              <svg
                width={16}
                height={16}
                fill="#ffffff"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="m3.009 12.403-1.839 1.85L0 13.098l3.135-3.105 1.14.015 3.135 3.18-1.14 1.14-1.75-1.77a7.5 7.5 0 0 0 14.1 2.974l1.219.896a9 9 0 0 1-16.83-4.025Zm16.485-.699L17.64 9.828l-1.14 1.14 3.135 3.165 1.14.015 3.135-3.105-1.125-1.14-1.791 1.77a9 9 0 0 0-16.665-4.38l1.215.89a7.5 7.5 0 0 1 13.95 3.52v.001Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                UpdateIsMapping(record, {
                  is_ignore: 1,
                });
              }}
              className="btn btn-sm btn-secondary ms-2"
              style={{ height: "35px", paddingTop: "7px" }}
            >
              Ignore
            </button>
          </div>
        );
      },
    },
  ];

  const columnsMapped = [
    {
      title: "#",
      dataIndex: "key",
      filterIndex: "key",
      align: "center",
      render: (e) => {
        return <div className="d-flex justify-content-center w-100">{e}</div>;
      },
    },
    {
      title: "Event Type",
      dataIndex: "event_type",
      filterIndex: "event_type",
      filters: getUniqueFilters(list, "event_type"), // Dynamically generated filters
      onFilter: (value, record) => record.event_type.indexOf(value) === 0,
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Event Name",
      dataIndex: "event_name",
      filterIndex: "event_name",
      filters: getUniqueFilters(list, "event_name"), // Dynamically generated filters
      onFilter: (value, record) => record.event_name.indexOf(value) === 0,

      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Line Item Name",
      dataIndex: "line_item_name",
      filterIndex: "line_item_name",
      filters: getUniqueFilters(list, "line_item_name"), // Dynamically generated filters
      onFilter: (value, record) => record.line_item_name.indexOf(value) === 0,
      render: (e) => {
        return e || "-";
      },
    },

    {
      title: "Our Line Item",
      dataIndex: "line_items",
      filterIndex: "line_items",
      filters: getUniqueFilters(list, "line_items"), // Dynamically generated filters
      onFilter: (value, record) => record?.line_items?.indexOf(value) === 0,
      render: (e, row) => {
        return e || "-";
      },
    },
    {
      title: "Action",

      render: (_, record) => (
        <div className="d-flex">
          <button
            onClick={() => {
              UpdateIsMappingMapped(record, {
                is_mapped: 0,
              });
            }}
            className="btn btn-sm btn-secondary ms-2"
            style={{ height: "35px", paddingTop: "7px" }}
          >
            Restore
          </button>
        </div>
      ),
    },
  ];
  const columnsIgnored = [
    {
      title: "#",
      dataIndex: "key",
      filterIndex: "key",
      align: "center",
      render: (e) => {
        return <div className="d-flex justify-content-center w-100">{e}</div>;
      },
    },
    {
      title: "Event Type",
      dataIndex: "event_type",
      filterIndex: "event_type",
      filters: getUniqueFilters(ignoreList, "event_type"), // Dynamically generated filters
      onFilter: (value, record) => record.event_type.indexOf(value) === 0,
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Event Name",
      dataIndex: "event_name",
      filterIndex: "event_name",
      filters: getUniqueFilters(ignoreList, "event_name"), // Dynamically generated filters
      onFilter: (value, record) => record.event_name.indexOf(value) === 0,
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Line Item Name",
      dataIndex: "line_item_name",
      filterIndex: "line_item_name",
      filters: getUniqueFilters(ignoreList, "line_item_name"), // Dynamically generated filters
      onFilter: (value, record) => record.line_item_name.indexOf(value) === 0,
      render: (e) => {
        return e || "-";
      },
    },

    {
      title: "Seller Name",
      dataIndex: "seller_name",
      filterIndex: "seller_name",
      filters: getUniqueFilters(ignoreList, "seller_name"), // Dynamically generated filters
      onFilter: (value, record) => record.seller_name.indexOf(value) === 0,
      render: (e) => {
        return e || "-";
      },
    },
    // {
    //   title: "Our Line Item",
    //   dataIndex: "line_items",
    //   filterIndex: "line_items",

    //   render: (e, row) => {
    //     return lineItemsList?.[row?.finance_line_item_id] || "-";
    //   },
    // },
    {
      title: "Action",

      render: (_, record) => (
        <div className="d-flex">
          <button
            onClick={() => {
              UpdateIsMapping(record, {
                is_ignore: 0,
              });
            }}
            className="btn btn-sm btn-secondary ms-2"
            style={{ height: "35px", paddingTop: "7px" }}
          >
            Restore
          </button>
        </div>
      ),
    },
  ];

  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const defaultActiveKey = queryParams.get("tabs") || "1";

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <div className="row g-5 g-xl-7   ">
        {/*begin::Col*/}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header" style={{ minHeight: "20px" }}>
              <div className="card-title">Finance Fees</div>
            </div>
            <div className="card-body pt-0 pb-2">
              <Tabs
                onChange={(e) => {
                  history.push(`/settings/finance-mapping?tabs=${e}`);
                }}
                defaultActiveKey={defaultActiveKey}
              >
                <TabPane tab="Pending Mapping" key="1">
                  <div>
                    <Table
                      dataSource={unList?.map((d, index) => ({
                        ...d,
                        index: index + 1,
                      }))}
                      fixed={true}
                      // onChange={handleChange}
                      sticky={{
                        offsetHeader: "0px",
                      }}
                      columns={columns_?.map((d) => ({
                        ...d,
                        className:
                          d?.title === "#"
                            ? "maxWidth-table"
                            : d?.title === "Count"
                            ? "maxWidth-table-custom"
                            : "minWidth-table",
                        ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                        render: (props, row, index) =>
                          RenderTable(props, row, index, d),
                      }))}
                      rowClassName={(record, index) => {
                        return index % 2 === 0 ? "even-row" : "odd-row";
                      }}
                      pagination={{
                        size: "default",
                        showSizeChanger: true,
                        defaultPageSize: 100,
                      }}
                      loading={unLoading}
                      bordered
                      size="small"
                      scroll={{ x: "max-content" }}
                    />
                    {/* <Pagination
                      loading={unLoading}
                      pageSize={pageSize}
                      page={page}
                      totalPage={totalPage}
                      onPerPage={(e) => {
                        setUnLoading(true);
                        setPageSize(e);
                        setPage(1);
                        GetUnList({
                          page: 1,
                          pageSize: e,
                          ...sortFilters,
                        });
                      }}
                      onPageNo={(e) => {
                        setUnLoading(true);
                        setPage(e);
                        GetUnList({
                          page: e,
                          ...sortFilters,
                        });
                      }}
                    /> */}
                  </div>
                </TabPane>
                <TabPane tab="Mapped" key="2">
                  <div>
                    <Table
                      dataSource={list?.map((d, i) => ({ ...d, key: i + 1 }))}
                      fixed={true}
                      sticky={{
                        offsetHeader: "0px",
                      }}
                      columns={columnsMapped?.map((d) => ({
                        ...d,
                        className:
                          d?.title === "#"
                            ? "maxWidth-table"
                            : "minWidth-table",
                        ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                        render: (props, row, index) =>
                          RenderTable(props, row, index, d),
                      }))}
                      loading={loading}
                      pagination={{
                        size: "default",
                        showSizeChanger: true,
                        defaultPageSize: 100,
                      }}
                      rowClassName={(record, index) => {
                        return index % 2 === 0 ? "even-row" : "odd-row";
                      }}
                      bordered
                      size="small"
                      scroll={{ x: "max-content" }}
                    />
                    {/* <Pagination
                      loading={loading}
                      pageSize={pageSize_}
                      page={page_}
                      totalPage={totalPage_}
                      onPerPage={(e) => {
                        setLoading(true);
                        setPageSize_(e);
                        setPage_(1);
                        GetList({
                          page: 1,
                          pageSize: e,
                        });
                      }}
                      onPageNo={(e) => {
                        setLoading(true);
                        setPage_(e);
                        GetList({
                          page: e,
                        });
                      }}
                    /> */}
                  </div>
                </TabPane>
                <TabPane tab="Ignored" key="3">
                  <div>
                    <Table
                      dataSource={ignoreList?.map((d, i) => ({
                        ...d,
                        key: i + 1,
                      }))}
                      fixed={true}
                      sticky={{
                        offsetHeader: "0px",
                      }}
                      columns={[
                        ...columnsIgnored?.map((d) => ({
                          ...d,
                          className:
                            d?.title === "#"
                              ? "maxWidth-table"
                              : "minWidth-table",
                          ...(d?.filterIndex
                            ? PropsFilter(d?.filterIndex)
                            : ""),
                          render: (props, row, index) =>
                            RenderTable(props, row, index, d),
                        })),
                      ]}
                      loading={ignoreLoading}
                      pagination={{
                        size: "default",
                        showSizeChanger: true,
                        defaultPageSize: 100,
                      }}
                      rowClassName={(record, index) => {
                        return index % 2 === 0 ? "even-row" : "odd-row";
                      }}
                      bordered
                      size="small"
                      scroll={{ x: "max-content" }}
                    />
                    {/* <Pagination
                      loading={ignoreLoading}
                      pageSize={pageSize_ignore}
                      page={page_ignore}
                      totalPage={totalPage_ignore}
                      onPerPage={(e) => {
                        setLoading(true);
                        setPageSize_ignore(e);
                        setPage_ignore(1);
                        GetList({
                          page: 1,
                          pageSize: e,
                        });
                      }}
                      onPageNo={(e) => {
                        setLoading(true);
                        setPage_ignore(e);
                        GetList({
                          page: e,
                        });
                      }}
                    /> */}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default MappingFinanceEvents;
